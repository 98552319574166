<template>
  <div>
    <form-answer
      :checklist="checklist"
      :breadcrumb="breadcrumb"
      :currentQuestion="currentQuestion"
    >
    </form-answer>
  </div>
</template>

<script>
import Breadcrumb from '@/layouts/components/content/breadcrumb/Breadcrumb.vue'
import PageTitle from '@/layouts/components/content/page-title/PageTitle.vue'
import { mapActions, mapGetters } from 'vuex'
import FormAnswer from '@/view/pages/app/questions/components/FormAnswer.vue'

export default {
  components: {
    Breadcrumb,
    PageTitle,
    FormAnswer
  },
  mounted() {
    this.getProject(this.$route.params.id)
    this.getChecklist(this.$route.params.checklistId)
    this.getNextQuestion({
      checklist_id: this.$route.params.checklistId
    })
  },
  data() {
    return {
      currentQuestion: null
    }
  },
  methods: {
    ...mapActions({
      getProject: 'project/getProject',
      getChecklist: 'checklist/getChecklist',
      getNextChecklistQuestion: 'question/getNextChecklistQuestion'
    }),
    async getNextQuestion(params) {
      this.currentQuestion = await this.getNextChecklistQuestion(params)
    }
  },
  computed: {
    ...mapGetters({
      checklist: 'checklist/checklist',
      project: 'project/project'
    }),
    breadcrumb() {
      if (this.project) {
        return [
          {
            path: '/app/projects',
            text: 'Proyectos'
          },
          {
            path: `/app/projects/${this.$route.params.id}/information`,
            text: this.project.name
          }
        ]
      }
      return [
        {
          path: '/app/projects',
          text: 'Proyectos'
        }
      ]
    }
  }
}
</script>
<style></style>
